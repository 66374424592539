import { Review } from "../components/Praises"

export const getReviews: () => Array<Review> = () => {
  reviews.sort(() => Math.random() - 0.5)
  return reviews.slice(0, 15)
}

export const mentoringReviews: Array<{ name: string, review: string, details: string[] }> = [
  {
    name: "Lorenzo Mateo",
    review: "If you are a person serious about learning the fundamentals of JavaScript and are willing to put in the work I would highly recommend bonsaiilabs.",
    details: [
      "My experience with the phase 1 JavaScript fundamental program was great. This program exceeded all my expectations for having a fundamental knowledge of JavaScript programming. The quality of the content was well organized. Within each session a visual presentation along with code samples were provided. The Visual presentation along with team’s understanding of JavaScript helped in my learning. The quizzes were also a great help in solidifying the material.",
      "The live one-on-one coding sessions was very instrumental for my understanding of JavaScript fundamentals. The team was very patient and understanding this hands on coding approach was very effective. We challenged ourselves to come up with the best solution for the coding task.",
      "The support team was very responsive, and had materials organized and ready for each session. If I had a question I can defer to anyone on the team.",
      "My JavaScript knowledge has increased immensely after the phase I program. Before I had very limited experience with vanilla JavaScript. Now I have working knowledge to manipulate the Document Object Model(DOM) and create high order functions.",
      "If you are a person serious about learning the fundamentals of JavaScript and are willing to put in the work I would highly recommend bonsaiilabs. The experience of bonsaiilabs hands on learning, live one-on-one code sessions, code samples, and quizzes will give you a solid foundation to become a better JavaScript professional programmer.",
      "I would enjoy continued work with bonsaiilabs for future front-end in learning. Thank you again for making this experience wonderful!!",
    ],
  },
  {
    name: "Matt Kristiansen",
    review: "If you've been trying to learn web development on your own and are overwhelmed by the volume of conflicting information available online, I can't recommend BonsaiiLabs enough.",
    details: [
      "Harit and Deeksha's guidance has been instrumental in building my understanding of web technologies. They are patient and attentive, and their teaching materials are unmatched in my experience.",
      "If you've been trying to learn web development on your own and are overwhelmed by the volume of conflicting information available online, I can't recommend Bonsaii Labs enough.",
    ],
  },
]

const reviews: Array<Review> = [
  { name: "triplehaudah", review: "Thanks, very clear and straight forward" },
  { name: "Suraj Paudel", review: "thanks it really helped me . keep it up" },
  { name: "jorge gatica", review: "excelente video muchas gracias!!!" },
  {
    name: "Zainul Khan",
    review: "Wow Ma'am this was so helpful and your voice is so good and clear that one can understand even without seeing the subtitles . Please make some videos on js projects for beginners. All the best for your channel ❣️",
  },
  { name: "Hugh Huynh", review: "Thanks for the tutorial, it was useful." },
  { name: "Carda Monkhi", review: "this was very informative for me as a beginner" },
  { name: "Dinesh Balaji Saravanan", review: "that content quality though 👏👏👏" },
  { name: "Oussama Khalfi", review: "thanks :) and we want more" },
  { name: "Nakul Singh ", review: "Your videos are good. But u should upload more video in a month. " },
  {
    name: "Saivivek Ravi",
    review: "Please make material ui reactjs full fledged course  in udemy your teaching is awesome there are more people who can buy on udemy or you can make free full fledged concepts of materialui reactjs",
  },
  { name: "KOGANTI ESWAR", review: "Nice . Keep it up" },
  { name: "Uday Singh", review: "Love your course and explain everything is great.... thank you" },
  { name: "chaitanya darekar", review: "thank you sm...very smooth explanation and on top of that a sweet voice..." },
  { name: "Héctor Serrano", review: "Love it guys. Always putting up great content" },
  { name: "IBRAHIM mohammed", review: "great video thank you very much" },
  { name: "Ibrahim Kaiser", review: "Thank you for this tutorial" },
  {
    name: "ARTSBY",
    review: "Thanks for all your videos. It really helped me. You have made it easier to learn and your sound is so beautiful.",
  },
  {
    name: "Hannes Simon",
    review: "Very well explained at a perfect speed (not too slow, not too fast) - thank you, this was very helpful to me!",
  },
  { name: "Ahsan-ul- haq", review: "Good work. Thanks :)" },
  { name: "Marlon EVSN", review: "This was an awesome video!!! Thank you." },
  { name: "Ibrahim Noufal ", review: "awsomeeeeeee, I subscribed <3 <3" },
  { name: "Pyrme", review: "loved it" },
  { name: "raj kumar", review: "as always great explanation 👍" },
  { name: "Cesar Garcia", review: "Thanks for your video .. this nice tutorials" },
  { name: "GizmoMaltese", review: "I like how these are concise and to the point." },
  { name: "The Digital Shubham", review: "very nice explanation with animations thanks sir" },
  { name: "Rafael Lopez", review: "So good! thank you" },
  { name: "Akshay Krishnan  ", review: "Top notch content!" },
  { name: "freejack1982", review: "You should definitely continue this series!" },
  { name: "shubham arora", review: "Very nice explanation sir thanks easy to understand🙏" },
  { name: "shubham arora", review: "very nice explanation. The way of ur explaining the tutorial very superb." },
  { name: "Janagar", review: "Great :) keep post more videos !!" },
  { name: "shubham arora", review: "perfect explanation sir thanks" },
  {
    name: "shubham arora ",
    review: "very nice explanation sir. Sir  please make a complete series on javascript for beginners.",
  },
  {
    review: "Great explanation! Looking forward to seeing more in the future. 🙂",
    name: "Youssef",
  },
]