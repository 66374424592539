import React, { useState } from "react"
import SectionP from "../Common/SectionP"
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core"
import SectionTitle from "../Common/SectionTitle"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: theme.palette.primary[400],
  },
}))

export const Reviews = ({ reviews }) => {
  const classes = useStyles()
  const [currentPraiseIndex, setPraiseIndex] = useState(0)

  const onClickNext = () => {
    let next =
      currentPraiseIndex === reviews.length - 1 ? 0 : currentPraiseIndex + 1
    setPraiseIndex(next)
  }

  const onClickPrevious = () => {
    let previous =
      currentPraiseIndex === 0 ? reviews.length - 1 : currentPraiseIndex - 1
    setPraiseIndex(previous)
  }

  return (
    <SectionP moreClasses={classes.section}>
      <SectionTitle>WHAT LEARNERS SAY</SectionTitle>

      <Grid container justify={"center"}>
        <IconButton aria-label={'Previous'} size="small" onClick={onClickPrevious} disableRipple={true}>
          <KeyboardArrowLeft fontSize="large" color="secondary" />
        </IconButton>

        <Grid item xs={8} xl={5}>
          <ReviewWithName
            review={reviews[currentPraiseIndex].review}
            name={reviews[currentPraiseIndex].name}
          />
        </Grid>

        <IconButton aria-label={'Next'} size="small" onClick={onClickNext} disableRipple={true}>
          <KeyboardArrowRight fontSize="large" color="secondary" />
        </IconButton>
      </Grid>
    </SectionP>
  )
}

export const ReviewWithName = ({ review, name }) => {
  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Typography align={'center'} variant="h5" color={"secondary"} paragraph={true}>
        {review}
      </Typography>
      <Typography variant="subtitle1" color={"secondary"}>{`- ` + name}</Typography>
    </Grid>
  )
}
