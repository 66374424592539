import * as React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"

interface SectionTitleProps {
  color?: "primary" | "secondary" | "textPrimary" | "textSecondary";
  children: React.ReactNode;
}

export default function SectionTitle({ color, children }: SectionTitleProps) {
  const classes = useStyles()
  return (
    <Grid container justify={"center"}>
      <Typography
        variant="h4"
        color={color || "secondary"}
        className={classes.root}
      >
        {children}
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(8),
  },
}))
