import * as React from "react"
import { useState } from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  MobileStepper,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import { ReviewWithName } from "./CourseDetail/Reviews"
import SectionP from "./Common/SectionP"
import SectionTitle from "./Common/SectionTitle"

export interface Review {
  review: string;
  name: string;
  detail?: string[];
}

interface PraisesProps {
  data: Array<Review>;
  auto?: boolean;
}

export default function Praises({ data, auto = true }: PraisesProps) {
  const [index, setIndex] = React.useState(0)

  const handleNext = () => {
    const next = index === data.length - 1 ? 0 : index + 1
    setIndex(next)
  }
  const handleBack = () => {
    const prev = index === 0 ? data.length - 1 : index - 1
    setIndex(prev)
  }
  const handleChangeIndex = index => setIndex(index)

  const classes = useStyles()

  return (
    <SectionP moreClasses={classes.section}>
      <SectionTitle>WHAT OUR LEARNERS SAY</SectionTitle>
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item xs={10}>
          {auto && <AutoSwipeableViews interval={5000} index={index} onChangeIndex={handleChangeIndex}>
            {data.map((review) => <DisplayReview review={review} key={review.name} classes={classes}/>)}
          </AutoSwipeableViews>}

          {!auto && <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
            {data.map((review) => <DisplayReview review={review} key={review.name} classes={classes}/>)}
          </SwipeableViews>}
        </Grid>
        <Grid item xs={10} md={6} className={classes.mobileStepper}>
          <MobileStepper
            steps={data.length}
            position="static"
            variant="dots"
            activeStep={index}
            classes={{
              root: classes.mobileStepperRoot,
              dot: classes.mobileStepperDot,
              dotActive: classes.mobileStepperDotActive,
            }}
            nextButton={
              <Button size="small" onClick={handleNext}>
                <KeyboardArrowRight color={"secondary"}/>
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack}>
                <KeyboardArrowLeft color={"secondary"}/>
              </Button>
            }
          />
        </Grid>
      </Grid>
    </SectionP>
  )
}

const DisplayReview = ({ review, classes }) => {
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const handleBtnClick = () => setShowDetail(!showDetail)

  return <Grid container direction={"column"} alignItems={"center"}>
    <Grid item>
      <ReviewWithName review={review.review} name={review.name}/>
    </Grid>
    {
      review.details && <Grid item className={classes.readMore}>
        <Button size={"large"}
                variant={"outlined"}
                onClick={handleBtnClick}
                color={"secondary"}>Read More</Button>
      </Grid>
    }
    {
      review.details && <Dialog open={showDetail} onClose={setShowDetail} fullScreen>
        <ShowDetailedReview reviewer={review.name}
                            details={review.details}
                            classes={classes}
                            onClick={handleBtnClick}/>
      </Dialog>
    }
  </Grid>
}

const ShowDetailedReview = ({ reviewer, details, classes, onClick }) => {
  return <>
    <AppBar color={"primary"} className={classes.reviewAppbar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClick} aria-label="close">
          <Close/>
        </IconButton>
      </Toolbar>
    </AppBar>
    <Grid container item justify={"center"}>
      {details.map(detail => <Grid key={detail} container item xs={9}>
        <Typography variant={"body1"} paragraph>{detail}</Typography>
      </Grid>)}
      <Grid container item xs={9} className={classes.reviewer}>
        <Typography variant={"subtitle1"} color={"textSecondary"}>- {reviewer}</Typography>
      </Grid>
    </Grid>
  </>
}

const AutoSwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
  section: {
    position: "relative",
    backgroundColor: theme.palette.primary[400],
  },
  mobileStepper: {
    paddingTop: theme.spacing(3),
  },
  mobileStepperRoot: {
    backgroundColor: theme.palette.primary[400],
  },
  mobileStepperDot: {
    backgroundColor: theme.palette.secondary.light,
  },
  mobileStepperDotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  readMore: {
    marginTop: theme.spacing(2),
  },
  reviewAppbar: {
    position: "relative",
    marginBottom: theme.spacing(5),
  },
  reviewer: {
    borderTop: `1px solid ${theme.palette.primary[50]}`,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
}))

